var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)", y: 400 },
                  paginationcustom: false,
                  defaultPagination: true,
                  onSelectChange: _vm.onSelectChange,
                  selectedRowKeys: _vm.selectedRowKeys,
                  checkboxOrRadio: "radio"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mt-2" },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.originalCost.label) }
                    },
                    [
                      _c("a-input-number", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.originalCost.decorator,
                            expression: "formRules.originalCost.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.originalCost.name,
                          placeholder: _vm.$t(
                            _vm.formRules.originalCost.placeholder
                          ),
                          parser: _vm.formatNumeric,
                          formatter: _vm.formatCurrency
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.salvageValue.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.salvageValue.decorator,
                            expression: "formRules.salvageValue.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.salvageValue.name,
                          placeholder: _vm.$t(
                            _vm.formRules.salvageValue.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t(_vm.formRules.acquisitionDate.label)
                      }
                    },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.acquisitionDate.decorator,
                            expression: "formRules.acquisitionDate.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.acquisitionDate.name,
                          placeholder: _vm.$t(
                            _vm.formRules.acquisitionDate.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.depreciate.label) }
                    },
                    [
                      _c("a-checkbox", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.depreciateCheckBox,
                          callback: function($$v) {
                            _vm.depreciateCheckBox = $$v
                          },
                          expression: "depreciateCheckBox"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.form } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.method.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.method.decorator,
                            expression: "formRules.method.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.method.name,
                          placeholder: _vm.$t(_vm.formRules.method.placeholder)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: { label: _vm.$t(_vm.formRules.lifeMonths.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.lifeMonths.decorator,
                            expression: "formRules.lifeMonths.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.lifeMonths.name,
                          placeholder: _vm.$t(
                            _vm.formRules.lifeMonths.placeholder
                          )
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticStyle: { "margin-top": "1rem" },
                      attrs: {
                        label: _vm.$t(_vm.formRules.remainingLifeMonths.label)
                      }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.remainingLifeMonths.decorator,
                            expression:
                              "formRules.remainingLifeMonths.decorator"
                          }
                        ],
                        attrs: {
                          disabled: "",
                          name: _vm.formRules.remainingLifeMonths.name,
                          placeholder: _vm.$t(
                            _vm.formRules.remainingLifeMonths.placeholder
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        [
          _c("a-divider", { attrs: { orientation: "left" } }, [
            _vm._v(" Depreciation Details ")
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  dataSource: _vm.dataSourceDetails,
                  columns: _vm.columnsTableDetails,
                  scroll: { x: "calc(700px + 50%)", y: 400 },
                  paginationcustom: false,
                  defaultPagination: true
                },
                on: { "on-columnClickedArray": _vm.handleClickColumnArray }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
































































































































import { debounce } from "@/helpers/debounce";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  AssetBookRelations,
  AssetDepreciations,
  ResponseGetDetailInquiry,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { generalJournalServices } from "@/services/generaljournal.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  numericOnly,
} from "@/validator/globalvalidator";
import moment from "moment";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "OpenDepreciation",
  props: {
    dataAssetHistory: {
      type: Object as PropType<ResponseGetDetailInquiry>,
    },
    currentTab: String,
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formatNumeric: numericOnly,
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      selectedRowKeys: [],
      depreciateCheckBox: true as boolean,
      totalElements: 1 as number,
      totalElementsDetails: 1 as number,
      limit: 10 as number,
      limitDetails: 10 as number,
      form: this.$form.createForm(this, { name: "openDepreciation" }),
      formRules: {
        originalCost: {
          label: "lbl_original_cost",
          name: "originalCost",
          placeholder: "lbl_original_cost_placeholder",
          decorator: ["originalCost"],
        },
        salvageValue: {
          label: "lbl_salvage_value",
          name: "salvageValue",
          placeholder: "lbl_salvage_value_placeholder",
          decorator: ["salvageValue"],
        },
        acquisitionDate: {
          label: "lbl_acquisition_date",
          name: "acquisitionDate",
          placeholder: "lbl_acquisition_date_placeholder",
          decorator: ["acquisitionDate"],
        },
        method: {
          label: "lbl_method",
          name: "method",
          placeholder: "lbl_method_placeholder",
          decorator: ["method"],
        },
        lifeMonths: {
          label: "lbl_life_months",
          name: "lifeMonths",
          placeholder: "lbl_life_months_placeholder",
          decorator: ["lifeMonths"],
        },
        remainingLifeMonths: {
          label: "lbl_remaining_life_month",
          name: "remainingLifeMonths",
          placeholder: "lbl_remaining_life_months_placeholder",
          decorator: ["remainingLifeMonths"],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "lbl_depreciate_placeholder",
        },
      },
      dataSource: [] as AssetBookRelations[],
      columnsTable: [
        {
          title: "Book",
          dataIndex: "bookName",
          key: "bookName",
        },
        {
          title: "Cost",
          dataIndex: "customCurrentCostAsset",
          key: "customCurrentCostAsset",
        },
        {
          title: "Net Book Value",
          dataIndex: "customNetBookValue",
          key: "customNetBookValue",
        },
        {
          title: "YTD Depreciation",
          dataIndex: "customYtdDepre",
          key: "customYtdDepre",
        },
        {
          title: "Accumulated Depreciation",
          dataIndex: "customAccumulateDepre",
          key: "customAccumulateDepre",
        },
      ],
      dataSourceDetails: [] as AssetDepreciations[],
      columnsTableDetails: [
        {
          title: "Period",
          dataIndex: "period",
          key: "period",
        },
        {
          title: "Total Amount",
          dataIndex: "customTotalAmount",
          key: "customTotalAmount",
        },
        {
          title: "Depreciation Amount",
          dataIndex: "customDepreAmount",
          key: "customDepreAmount",
        },
        {
          title: "Adjustment Amount",
          dataIndex: "customAdjustmentAmount",
          key: "customAdjustmentAmount",
        },
        {
          title: "Journal Number",
          dataIndex: "journalNumbers",
          key: "journalNumbers",
          scopedSlots: { customRender: "clickColumnArrayMode" },
        },
      ],
    };
  },
  methods: {
    async handleClickColumnArray(_record, objColumnNameValue) {
      let data;
      const paramsJournal = {
        page: 0,
        limit: 10,
        search: `name~${objColumnNameValue.value}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (objColumnNameValue.column === "journalNumbers") {
        data = await generalJournalServices.listGeneralJournal(paramsJournal);
        if (data.data.length > 0) {
          this.$confirm({
            title: "You will be redirected to another page, are you sure?",
            onOk: () => {
              this.$router.push(
                `/generaljournal/journal/detail/${data.data[0].id}`
              );
            },
            onCancel() {
              return;
            },
          });
        }
      }
    },
    onSelectChange(value) {
      this.selectedRowKeys = value;
      this.dataSourceDetails = this.dataSource[
        value[0]
      ].assetDepreciations.filter(
        data => data.period === null || !data.period.includes("Adj")
      );
      this.depreciateCheckBox = this.dataSource[value[0]].depreciate;
      this.form.setFieldsValue({
        acquisitionDate: this.dataSource[value[0]].acquisitionDate,
        lifeMonths: this.dataSource[value[0]].currentLifeMonth,
        remainingLifeMonths: this.dataSource[value[0]].remainingLifeMonth,
        originalCost: this.dataSource[value[0]].currentCostAsset,
      });
    },
  },
  watch: {
    dataAssetHistory: {
      immediate: true,
      deep: true,
      handler(newValue) {
        debounce(() => {
          this.form.setFieldsValue({
            originalCost: newValue.originalCost,
            salvageValue: newValue.salvageValue,
            acquisitionDate: newValue.acquisitionDate
              ? moment(newValue.acquisitionDate, "DD-MMM-yyyy")
              : null,
            method: newValue.bookMethod,
            lifeMonths: newValue.lifeMonths,
            remainingLifeMonths: newValue.remainingLifeMonth,
          });
          this.depreciateCheckBox = newValue.depreciate;
          this.dataSource = newValue.assetBookRelations;
        }, 500);
      },
    },
    dataSource() {
      if (this.dataSource && this.dataSource.length > 0) {
        this.dataSource.forEach(dataMap => {
          dataMap.customCurrentCostAsset = currencyFormat(
            dataMap.currentCostAsset
          );
          dataMap.customNetBookValue = currencyFormat(dataMap.netBookValue);
          dataMap.customYtdDepre = currencyFormat(dataMap.ytdDepre);
          dataMap.customAccumulateDepre = currencyFormat(
            dataMap.accumulateDepre
          );
        });
      }
    },
    dataSourceDetails() {
      this.dataSourceDetails.forEach(dataMap => {
        dataMap.customTotalAmount = currencyFormat(dataMap.totalAmount);
        dataMap.customDepreAmount = currencyFormat(dataMap.depreAmount);
        dataMap.customAdjustmentAmount = currencyFormat(
          dataMap.adjustmentAmount
        );
      });
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
